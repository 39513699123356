import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Redirector } from "@truedat/core/components";
import {
  Auth0Callback,
  OpenIDConnect,
  PrivateRoute,
  SamlCallback,
  UnauthorizedRoute
} from "@truedat/auth/components";
import { CALLBACK, LOGIN, UNAUTHORIZED } from "@truedat/core/routes";
import Unauthorized from "components/Unauthorized";
import Navigation from "components/Navigation";
import { CanInitLoader } from "@truedat/auth/components";
import { AuthMethodsLoader } from "@truedat/auth/components";
import Login from "./login/Login";
import AppSetup from "./setup/AppSetup";

// auth0: <Route path="/callback">
// SAML: devServer.proxy "/callback" -> kong (td-auth)

export const AppRoutes = ({ redirect, canInit, canInitLoaded }) => (
  <Switch>
    <Route
      exact
      path={CALLBACK}
      render={() => (
        <>
          <AuthMethodsLoader />
          <Auth0Callback />
        </>
      )}
    />
    <Route exact path="/saml" render={() => <SamlCallback />} />
    <Route exact path="/oidc" render={() => <OpenIDConnect />} />
    <Route
      exact
      path={LOGIN}
      render={() => (
        <>
          <CanInitLoader />
          {canInitLoaded && canInit && <AppSetup />}
          {canInitLoaded && !canInit && <Login />}
        </>
      )}
    />
    {!redirect && (
      <UnauthorizedRoute exact path={UNAUTHORIZED} component={Unauthorized} />
    )}
    {redirect && <Redirector redirect={redirect} />}
    {!redirect && <PrivateRoute path="/" component={Navigation} />}
  </Switch>
);

AppRoutes.propTypes = {
  redirect: PropTypes.string,
  canInit: PropTypes.bool,
  canInitLoaded: PropTypes.bool
};

const mapStateToProps = ({
  auditRedirect,
  authRedirect,
  conceptRedirect,
  configurationRedirect,
  ingestRedirect,
  domainRedirect,
  structureRedirect,
  groupRedirect,
  userRedirect,
  relationRedirect,
  roleRedirect,
  ruleRedirect,
  ruleImplementationRedirect,
  searchRedirect,
  templateRedirect,
  sourceRedirect,
  canInit,
  canInitLoaded
}) => ({
  redirect:
    auditRedirect ||
    authRedirect ||
    conceptRedirect ||
    configurationRedirect ||
    ingestRedirect ||
    domainRedirect ||
    structureRedirect ||
    groupRedirect ||
    userRedirect ||
    relationRedirect ||
    roleRedirect ||
    ruleRedirect ||
    ruleImplementationRedirect ||
    searchRedirect ||
    templateRedirect ||
    sourceRedirect,
  canInit,
  canInitLoaded
});

export default connect(mapStateToProps)(AppRoutes);
