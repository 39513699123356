import { defaultConceptColumns } from "@truedat/bg/concepts/selectors";

const customColumns = {
  "TEST SUBSCOPE": [
    ...defaultConceptColumns,
    {
      name: "type",
      fieldDecorator: type => type,
      sort: { name: "type" }
    },
    {
      name: "content.subscope_field",
      fieldDecorator: subscope_field => subscope_field,
      sort: { name: "content.subscope_field" }
    }
  ],

  "Modelos analíticos": [
    ...defaultConceptColumns.filter(col => col.name !== "link_count")
  ],

  pendingConcepts: [
    ...defaultConceptColumns,
    {
      name: "type",
      fieldDecorator: type => type,
      sort: { name: "type" }
    }
  ],
  deprecatedConcepts: defaultConceptColumns.filter(col =>
    ["name", "domain", "last_change_at"].includes(col.name)
  )
};

const initialState = {
  concepts: [...defaultConceptColumns],
  ...customColumns
};

export const conceptsColumns = (state = initialState) => state;
