import React from "react";
import _ from "lodash/fp";
import { defaultStructureColumns } from "@truedat/dd/selectors";

const defaultColumnsWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureColumns);

const pathDecorator = path => (
  <span title={_.join(" › ")(path)}>
    {_.flow(_.join(" › "), _.truncate({ length: 90 }))(path)}{" "}
  </span>
);

const path = {
  name: "path",
  fieldDecorator: pathDecorator,
  sort: { name: "path_sort" }
};

const initialState = [...defaultColumnsWithoutGroup, path];

export const structuresColumns = (state = initialState) => state;
