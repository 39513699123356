import { all, setContext } from "redux-saga/effects";
import auditSagas from "@truedat/audit/sagas";
import authSagas from "@truedat/auth/sagas";
import bgSagas from "@truedat/bg/sagas";
import coreSagas from "@truedat/core/sagas";
import ddSagas from "@truedat/dd/sagas";
import dfSagas from "@truedat/df/sagas";
import dqSagas from "@truedat/dq/sagas";
import ieSagas from "@truedat/ie/sagas";
import seSagas from "@truedat/se/sagas";
import lmSagas from "@truedat/lm/sagas";
import cxSagas from "@truedat/cx/sagas";

const makeSagas = client => {
  return function* () {
    yield setContext({ client });
    yield all([
      ...auditSagas,
      ...authSagas,
      ...bgSagas,
      ...coreSagas,
      ...ddSagas,
      ...dfSagas,
      ...dqSagas,
      ...ieSagas,
      ...seSagas,
      ...lmSagas,
      ...cxSagas
    ]);
  };
};

export default makeSagas;
