import _ from "lodash/fp";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { linkTo } from "@truedat/core/routes";
import { linksDecorator } from "@truedat/dd/selectors/getStructureFieldColumns";

const translateDecoratorWithDefault = (text, default_text) => (
  <FormattedMessage id={text} defaultMessage={default_text} />
);

const structureTypeDecorator = ({ structure: { type, data_structure_type } }) =>
  translateDecoratorWithDefault(
    `structure.type.${type}.text`,
    _.prop("translation")(data_structure_type) || type
  );

const linkToStructureDecorator = ({
  structure: { data_structure_id: id, name }
}) => <Link to={linkTo.STRUCTURE({ id })}>{name}</Link>;

export const initialState = [
  {
    name: "name",
    fieldSelector: _.pick(["structure.data_structure_id", "structure.name"]),
    fieldDecorator: linkToStructureDecorator
  },
  {
    name: "type",
    fieldSelector: _.pick(["structure.type", "structure.data_structure_type"]),
    fieldDecorator: structureTypeDecorator
  },
  {
    name: "links",
    fieldDecorator: linksDecorator
  }
];

export const structureRelationColumns = (state = initialState) => state;
