import React from "react";
import _ from "lodash/fp";
import { defaultStructureNoteColumns } from "@truedat/dd/selectors";

const defaultColumnsWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureNoteColumns);

const type = {
  name: "type",
  sort: { name: "type.sort" },
  width: 2
};

const initialState = [...defaultColumnsWithoutGroup, type];

export const structuresNotesColumns = (state = initialState) => state;
