import React from "react";
import { useIntl } from "react-intl";

export default function SearchHomeAddon() {
  const { formatMessage } = useIntl();

  const blockStyles = {
    display: "block",
    background: "#ffffff",
    margin: "10px",
    padding: "20px",
    width: "calc(25% - 20px)",
    boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    transition: "transform 0.3s ease",
    position: "relative",
    textAlign: "center",
    textDecoration: "none",
    color: "inherit"
  };

  const iconStyles = {
    display: "inline-block",
    fontSize: "2em",
    color: "#0071c2",
    marginBottom: "20px"
  };

  const h2Styles = {
    color: "#0071c2",
    fontSize: "1.2em"
  };

  const pStyles = {
    color: "#666",
    fontSize: "0.9em"
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "20px"
        }}
      >
        <a href="/concepts" style={blockStyles}>
          <i className="icon tags large" style={iconStyles}></i>
          <h2 style={h2Styles}>{formatMessage({ id: "search.glossary" })}</h2>
          <p style={pStyles}>{formatMessage({ id: "search.glossary_desc" })}</p>
        </a>

        <a href="/structures" style={blockStyles}>
          <i className=" icon block layout large" style={iconStyles}></i>
          <h2 style={h2Styles}>
            {formatMessage({ id: "search.data_catalog" })}
          </h2>
          <p style={pStyles}>
            {formatMessage({ id: "search.data_catalog_desc" })}
          </p>
        </a>

        <a href="/structuresGrantRequests" style={blockStyles}>
          <i className="icon key large" style={iconStyles}></i>
          <h2 style={h2Styles}>{formatMessage({ id: "search.grants" })}</h2>
          <p style={pStyles}>{formatMessage({ id: "search.grants_desc" })}</p>
        </a>

        <a href="/rules" style={blockStyles}>
          <i className="icon check square large" style={iconStyles}></i>
          <h2 style={h2Styles}>
            {formatMessage({ id: "search.data_quality" })}
          </h2>
          <p style={pStyles}>
            {formatMessage({ id: "search.data_quality_desc" })}
          </p>
        </a>
      </div>
    </>
  );
}
