import _ from "lodash/fp";
import { compile } from "path-to-regexp";

const routes = {
  DASHBOARD: "/dashboard",
  DQ_DASHBOARD: "/quality_dashboard",
  HOME: "/"
};

const linkTo = _.mapValues(compile, routes);

export { routes as default, linkTo };
