export default {
  "filters.agrupacion": "Information Group",
  "filters.calculan": "Calculado por",
  "filters.confidencialidad": "Confidentiality",
  "filters.pais": "Country",
  "filters.criticidad": "Criticality",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.dominio_informacion": "Management Information",
  "filters.fase1": "DWH Phase I",
  "filters.functional_owner": "Functional Owner",
  "filters.idioma": "Language",
  "filters.naturaleza": "Nature",
  "filters.sensible": "Sensitive",
  "filters.sujeto_gdpr": "GDPR Compliance",
  "filters.temporalidad": "Temporal aggregation",
  "filters.tipo": "Concept Type",
  "filters._confidential": "Confidential",
  "filters.gdpr": "GDPR",
  "filters.history": "History",
  "filters.periodicity": "Periodicity",
  "filters.scope": "Scope",
  "filters.owner": "Data Owner",
  "filters.dq_periodicity": "Periodicity",
  "filters.dq_principle": "Data Quality principle",
  "filters.class.raw": "Class",
  "filters.class.raw.field": "Field",

  "login.form.actions.auth0": "Auth0 access",
  "login.form.actions.oidc": "Google Login",
  "login.description": "Data Governance",

  "concepts.actions.upload.confirmation.header": "Upload concepts file",
  "domain.members.delete": "Delete member",
  "structure.mark_as_confidential": "Mark as confidential",
  "rule.type.min_date": "Minimum date",

  "navigation.glossary.concepts": "Glossary",
  "navigation.dictionary.structures": "Catalog",
  "navigation.ingest.ingests": "Processes",
  "navigation.quality.rules": "Quality",
  "navigation.quality.dashboard": "Quality Dashboard",

  "structure.lopd": "GDPR Compliance",
  "conceptRelations.relationType": "Relation's type",

  //Structure types

  "structure.type.Column.text": "Column",
  "structure.type.Column.icon": "columns",

  "structure.type.Table.text": "Table",
  "structure.type.Table.icon": "table",

  "structure.type.table.text": "Table 2",
  "structure.type.table.icon": "table",

  "structure.type.Database.text": "Database",
  "structure.type.Database.icon": "database",

  "structure.type.Schema.text": "Schema",
  "structure.type.Schema.icon": "database",

  "structure.type.Report.text": "Report",
  "structure.type.Report.icon": "chart bar",
  "structure.type.Report.lineage.icon": "chart-bar",

  "structure.type.Carpeta.text": "Folder",
  "structure.type.Carpeta.icon": "folder",

  "structure.type.Folder.text": "Folder 2",
  "structure.type.Folder.icon": "folder",

  "structure.type.Documento.text": "Document",
  "structure.type.Documento.icon": "file outline",
  "structure.type.Documento.lineage.icon": "file",

  "structure.type.Informe.text": "Dashboard",
  "structure.type.Informe.icon": "chart bar",
  "structure.type.Informe.lineage.icon": "chart-bar",

  "structure.type.Field.text": "Field",
  "structure.type.Field.icon": "columns",

  "structure.type.BASE TABLE.text": "Base table",
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.DssTypeDocumentDefinition.text": "DssTypeDocumentDefinition",
  "structure.type.DssTypeDocumentDefinition.icon": "file outline",
  "structure.type.DssTypeDocumentDefinition.lineage.icon": "file",

  "structure.type.DssTypeReportDefinition.text": "DssTypeReportDefinition",
  "structure.type.DssTypeReportDefinition.icon": "chart bar",
  "structure.type.DssTypeReportDefinition.lineage.icon": "chart-bar",

  "structure.type.DssTypeFolder.text": "DssTypeFolder",
  "structure.type.DssTypeFolder.icon": "folder",

  "structure.type.Calculated Table.text": "Calculated Table",
  "structure.type.Calculated Table.icon": "table",

  "structure.type.USER_TABLE.text": "User table",
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.VIEW.text": "View",
  "structure.type.VIEW.icon": "table",

  "structure.type.DIRECTORY.text": "Directory",
  "structure.type.DIRECTORY.icon": "folder",

  "structure.type.FILE.text": "File",
  "structure.type.FILE.icon": "file outline",
  "structure.type.FILE.lineage.icon": "file",

  "structure.type.EXTERNAL_TABLE.text": "External table",
  "structure.type.EXTERNAL_TABLE.icon": "table",

  "quality.result_avg": "Current quality (%)",
  "quality.last_execution_at": "Last execution",

  //Business concepts relations
  "source.bc_father_of": "Father of",
  "target.bc_father_of": "Child of",
  "source.bc_related_to": "Related to",
  "target.bc_related_to": "Related to",
  "source.bc_translated_to_spanish": "Translated to spanish as",
  "target.bc_translated_to_spanish": "Translated to english as",
  "source.bc_parent": "Father of",
  "target.bc_parent": "Child of",

  //Implementations parameters
  "ruleImplementation.props.campo": "Field",
  "ruleImplementation.props.campo.placeholder":
    "Select the corresponding field",

  "ruleImplementation.props.campo_maestro": "Master field",
  "ruleImplementation.props.campo_maestro.placeholder":
    "Select the corresponding master field",

  // Ingests
  "alert.ingestAction.failed.header": "Error creating data request",
  "alert.upload.failed.content":
    "Error loading files, no inserts have been made. Error in data request [{row}], {error}",
  "alert.upload.success.content": "Loaded {count} data requests successfully",
  "filters.template": "Type",
  "ingest.error.existing.ingest":
    "({text}) There is already a data request term with the indicated name",
  "ingest.events.add_resource_field":
    "linked a structure with the data request",
  "ingest.events.create_ingest_draft": "created a new draft data request",
  "ingest.events.delete_ingest_draft": "deleted a draft data request",
  "ingest.events.delete_resource_field":
    "unlinked a structure from the data request",
  "ingest.events.ingest_deprecated": "Data request archived",
  "ingest.events.ingest_published": "Data request published",
  "ingest.events.ingest_rejected": "Data request rejected",
  "ingest.events.ingest_rejection_canceled": "Data request rejection canceled",
  "ingest.events.ingest_sent_for_approval": "Data request sent for approval",
  "ingest.events.new_ingest_draft":
    "created a new draft form a published data request",
  "ingest.events.update_ingest_draft": "Draft updated",
  "ingest.relatedTo.ingest": "Related to data request",
  "ingestRelations.relatedDataIngest": "Related Data requests",
  "ingests.actions.create": "New Data request",
  "ingests.actions.delete.confirmation.content":
    "The Data request will be deleted. Are you sure?",
  "ingests.actions.delete.confirmation.header": "Delete Data request",
  "ingests.actions.deprecate.confirmation.content":
    "The Data request will be deprecated. Are you sure?",
  "ingests.actions.deprecate.confirmation.header": "Deprecate Data request",
  "ingests.actions.upload.confirmation.header": "Upload data requests file",
  "ingests.crumbs.top": "Data requests",
  "ingests.header": "Data requests",
  "ingests.header.duplicate": "Duplicate Data request",
  "ingests.header.edit": "Edit Data request",
  "ingests.props.ingest_id": "Data request Id",
  "ingests.props.name": "Data request",
  "ingests.search.placeholder": "Search data requests...",
  "ingests.search.results.empty": "No data requests found",
  "ingests.subheader": "Query and manage data requests",
  "navigation.ingest": "Data request",
  "navigation.ingests": "Data requests",
  "parentIngest.selector.label": "Parent data request",
  "parentIngest.selector.placeholder": "Select an data request...",
  "tabs.ie.ingest": "Data request",
  "tabs.ie.relationsIngests": "Related Data requests",
  "tabs.se.ingests": "Data requests",
  "ingest.relations.actions.data_structure.delete.confirmation.content":
    "Vinculation between data request and structure will be deleted. Are you sure?",
  "filters.tipo_ingesta": "Request Type",
  "ingests.tabs.published": "Published",
  "ingest.events.relation_created": "Added relation",
  "ingest.events.relation_deleted": "Deleted Relation",

  "appSetup.welcome.message": "Welcome to Truedat",
  "appSetup.configuration.message":
    "It seems that everything has been installed correctly. Let's begin configuring application so that we can start using it after a few simple steps",
  "appSetup.begin.button": "Begin",

  "filtersGrid.field.modifier.cast_as_date": "Cast as date",
  "filtersGrid.field.modifier.cast_as_timestamp": "Cast as timestamp",
  "filtersGrid.field.modifier.cast_as_date.format": "Date format",
  "filtersGrid.field.modifier.concat": "Concatenate",
  "filtersGrid.field.modifier.concat.with_fields": "Fields",
  "filtersGrid.field.modifier.to_string": "To string",
  "filtersGrid.field.modifier.substring": "Substring",
  "filtersGrid.field.modifier.substring.start": "String start position",
  "filtersGrid.field.modifier.substring.end": "String end position"
};
